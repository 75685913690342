import axios from "axios";

export default {
  async getItemNames() {
    return await axios
      .get(`inventory/item-name`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPoStocks(offset, limit) {
    return await axios
      .get(
        `purchase-internal-transfer/po-stock?offset=${offset}&limit=${limit}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async poStockSearch(params) {
    return await axios
      .get(`purchase-internal-transfer/po-stocks/search`, { params: params })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMarks() {
    return await axios
      .get(`marks`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getCompanyWareHouse() {
    return await axios
      .get(`company-warehouse`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
