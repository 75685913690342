<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      PO Stock
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text>
                <v-card-text class="px-0 py-0">
                  <v-row>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Item Name</label>

                      <v-autocomplete :items="item_names" item-value="item_name" item-text="item_name" v-model="item_name"
                        class="
                                          input-style
                                          font-size-input
                                          text-light-input
                                          placeholder-light
                                          select-style
                                          mt-3
                                        " outlined chips multiple height="46" single-line
                        placeholder="Select Item Name">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{
                              item.item_name
                            }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ item_name.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                              <v-icon :color="item_name.length > 0 ? 'indigo darken-4' : ''
                                ">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Mark</label>

                      <v-autocomplete :items="marks" item-value="id" item-text="mark_name" v-model="mark" class="
                                          input-style
                                          font-size-input
                                          text-light-input
                                          placeholder-light
                                          select-style
                                          mt-3
                                        " outlined chips multiple height="46" single-line placeholder="Select Mark">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{
                              item.mark_name
                            }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ mark.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle1">
                            <v-list-item-action>
                              <v-icon :color="mark.length > 0 ? 'indigo darken-4' : ''
                                ">
                                {{ icon1 }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Company WareHouse</label>
                      <v-autocomplete :items="company_warehouses" item-value="id" item-text="name"
                        v-model="company_warehouse" class="
                                          input-style
                                          font-size-input
                                          text-light-input
                                          placeholder-light
                                          select-style
                                          mt-3
                                        " outlined chips multiple height="46" single-line
                        placeholder="Select Company WareHouse">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{
                              item.name
                            }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ company_warehouse.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle2">
                            <v-list-item-action>
                              <v-icon :color="company_warehouse.length > 0
                                ? 'indigo darken-4'
                                : ''
                                ">
                                {{ icon2 }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete> </v-col><v-col cols="12" sm="6" md="3" xs="12" lg="3">
                      <label class="text-md text-typo font-weight-bolder ms-1">Grade</label>
                      <v-autocomplete :items="grades" item-value="id" item-text="grade_name" v-model="grade" class="
                                          input-style
                                          font-size-input
                                          text-light-input
                                          placeholder-light
                                          select-style
                                          mt-3
                                        " outlined chips multiple height="46" single-line placeholder="Select Grade">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{
                              item.grade_name
                            }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ grade.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle3">
                            <v-list-item-action>
                              <v-icon :color="grade.length > 0 ? 'indigo darken-4' : ''
                                ">
                                {{ icon3 }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="card-padding d-flex justify-end">
                  <v-btn @click="cancel" elevation="0" :ripple="false" height="46" class="
                                      font-weight-bold
                                      text-capitalize
                                      btn-ls btn-secondary
                                      bg-light
                                      py-3
                                      px-6
                                    ">Cancel</v-btn>

                  <v-btn @click="search" elevation="0" :ripple="false" height="46" dark class="
                                      font-weight-bold
                                      text-capitalize
                                      btn-ls btn-primary
                                      bg-success
                                      py-3
                                      px-6
                                    ">Search</v-btn>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding">
          <v-data-table :headers="headers" :items="poStockData" fixed-header :pageCount="numberOfPages"
            :server-items-length="total_rows" :options.sync="options" class="table">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>PO Stock</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn @click="excelExport" elevation="0" :ripple="false" height="43" dark class="
                                    font-weight-bold
                                    text-capitalize
                                    btn-primary
                                    bg-success
                                    py-5
                                    px-5
                                    shadow
                                  ">
                  <v-icon size="20" class="text-white mr-1">
                    fas fa-cloud-download-alt
                  </v-icon>
                  Download
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:[`item.auction_date`]="{ item }">
              {{ formatAuctionDate(item.auction_date) }}
            </template>
            <template v-slot:[`item.gp_date`]="{ item }">
              {{ dateFormater(item.gp_date) }}
            </template>
            <template v-slot:[`item.sale_date`]="{ item }">
              {{ dateFormater(item.sale_date) }}
            </template>
            <template v-slot:[`item.prompt_date`]="{ item }">
              {{ dateFormater(item.prompt_date) }}
            </template>
            <template v-slot:[`item.sl_no`]="{ item }">
              {{ findSerialNo(item) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import { json2excel } from "js2excel";
import masterApi from "../../../master/master-get-api";
import formatDate from "../../../../global-data/dateFormatter";
export default {
  data() {
    return {
      overlay: false,
      item_names: [],
      item_name: [],
      marks: [],
      mark: [],
      grades: [],
      grade: [],
      poStockData: [],
      company_warehouses: [],
      company_warehouse: [],
      headers: [
        {
          text: "#",
          // width: "80",
          value: "sl_no",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Type",
          // // width: "100",
          value: "purchase_type",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Vendor",
          // width: "150",
          value: "vendor_name",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Warehouse",
          width: "150",
          value: "warehouse_name",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Auction Centre",
          // width: "100",
          value: "auction_center",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Season",
          width: "120",
          value: "season_name",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Sale/Offer",
          width: "150",
          value: "sale",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "PO Number",
          width: "150",
          value: "po_number",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Sale Date",
          width: "120",
          value: "sale_date",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Auction Date",
          width: "120",
          value: "auction_date",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Prompt Date",
          width: "120",
          value: "prompt_date",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Lot No",
          width: "150",
          value: "lot_no",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Tea Type",
          width: "150",
          value: "tea_type",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Mark",
          // width: "150",
          value: "mark",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Grade",
          width: "120",
          value: "grade",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Invoice No.",
          width: "150",
          value: "invoice_number",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "No. of Packs",
          width: "100",
          value: "no_packages",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Invoice Weight",
          width: "120",
          value: "invoice_weight",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Lot Price",
          width: "120",
          value: "lot_price",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Total Cost",
          width: "100",
          value: "total_cost",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "Item Code",
          width: "130",
          value: "item_name",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "GP No.",
          width: "100",
          value: "gp_no",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
        {
          text: "GP Date",
          width: "120",
          value: "gp_date",

          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
        },
      ],
      // Pagination
      options: {},
      numberOfPages: 100,
      total_rows: 10,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    toggle1() {
      this.$nextTick(() => {
        if (this.selectAllMark) {
          this.mark = [];
        } else {
          this.mark = this.marks.slice();
        }
      });
    },
    toggle2() {
      this.$nextTick(() => {
        if (this.selectAllCompanyWareHouse) {
          this.company_warehouse = [];
        } else {
          this.company_warehouse = this.company_warehouses.slice();
        }
      });
    },
    toggle3() {
      this.$nextTick(() => {
        if (this.selectAllGrade) {
          this.grade = [];
        } else {
          this.grade = this.grades.slice();
        }
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.item_name = [];
        } else {
          this.item_name = this.item_names.slice();
        }
      });
    },
    async initialize() {
      this.overlay = true;
      this.item_names = await api.getItemNames();
      const { page, itemsPerPage } = this.options;
      let result = await api.getPoStocks(page - 1, itemsPerPage);

      this.total_rows = result[0].total_rows;
      this.poStockData = result[0].data;

      this.company_warehouses = await api.getCompanyWareHouse();
      this.marks = await api.getMarks();
      this.grades = await masterApi.getGrade();
      this.overlay = false;
    },
    cancel() {
      this.item_names = [];
      this.marks = [];
      this.company_warehouses = [];
    },
    async search() {
      let item_names = [];
      let mark = [];
      let company_warehouse = [];
      let grade = [];
      // Checking Item name -SELECT ALL-
      if (this.item_name.length == this.item_names.length) {
        this.item_name.forEach((el) => {
          item_names.push(el.item_name);
        });
      }
      // SELECT SOME
      if (
        this.item_name.length > 0 &&
        this.item_name.length != this.item_names.length
      ) {
        item_names = this.item_name;
      }
      // Checking Marks -SELECT ALL-
      if (this.mark.length == this.marks.length) {
        this.mark.forEach((el) => {
          mark.push(el.id);
        });
      }
      // SELECT SOME
      if (this.mark.length > 0 && this.mark.length != this.marks.length) {
        mark = this.mark;
      }
      // Checking Warehouse -SELECT ALL-
      if (this.company_warehouse.length == this.company_warehouses.length) {
        this.company_warehouse.forEach((el) => {
          company_warehouse.push(el.id);
        });
      }
      // SELECT SOME
      if (
        this.company_warehouse.length > 0 &&
        this.company_warehouse.length != this.company_warehouses.length
      ) {
        company_warehouse = this.company_warehouse;
      }
      // Checking Grade -SELECT ALL-
      if (this.grade.length == this.grades.length) {
        this.grade.forEach((el) => {
          grade.push(el.id);
        });
      }
      // SELECT SOME
      if (this.grade.length > 0 && this.grade.length != this.grades.length) {
        grade = this.grade;
      }
      const { page, itemsPerPage } = this.options;

      let params = {
        item_name: item_names,
        mark: mark,
        company_warehouse: company_warehouse,
        grade: grade,
        limit: itemsPerPage,
        offset: page - 1,
      };
      this.poStockData = [];
      try {
        this.overlay = true;

        let result = await api.poStockSearch(params);
        this.total_rows = result[0].total_rows;
        this.poStockData = result[0].data;

        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    findSerialNo(item) {
      return this.poStockData.indexOf(item) + 1;
    },
    async excelExport() {
      this.options = {
        page: 1,
        itemsPerPage: -1,
      };

      await this.search();
      let data = this.poStockData.map((item, i) => {
        return {
          "#": i + 1,
          "Type": item.purchase_type,
          "Vendor": item.vendor_name,
          "Warehouse": item.warehouse_name,
          "Auction Centre": item.auction_center,
          "Season": item.season_name,
          "Sale/Offer": item.sale,
          "PO Number": item.po_number,
          "Sale Date": this.dateFormater(item.sale_date),
          "Auction Date": this.formatAuctionDate(item.auction_date),
          "Prompt Date": this.dateFormater(item.prompt_date),
          "Lot No": item.lot_no,
          "Tea Type": item.tea_type,
          "Mark": item.mark,
          "Grade": item.grade,
          "Invoice No.": item.invoice_number,
          "No. of packs": item.no_packages,
          "Net": item.net_weight,
          "Invoice Weight": item.invoice_weight,
          "Lot Price": item.lot_price,
          "Total Cost": item.total_cost,
          "Item Code": item.item_name,
          "Period Of Manufacture": item.period_of_manufacture,
          "GP No.": item.gp_no,
          "GP Date": this.dateFormater(item.gp_date),
          "Company Warehouse": item.company_warehouse,
        };
      });

      let date_time = new Date();
      date_time = `${(date_time.getMonth() + 1).toString().padStart(2, "0")}/${date_time.getDate().toString().padStart(2, "0")}/${date_time
        .getFullYear()
        .toString()}`;
      let fileName = `PO-Stock-Report-` + date_time.replaceAll(`/`, `-`);

      try {
        json2excel({
          data,
          name: fileName,
          formateDate: "yyyy/mm/dd",
        });
      } catch (e) {
        console.error("Excel export error:", e);
      }
    },
    formatAuctionDate(date) {
      return date.substr(0, 10);
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    showNoDataAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,

        timer: 3000,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    dateFormater(date) {
      if (date === null) {
        return "";
      } else {
        return formatDate.formatDate(date);
      }
    },
  },
  computed: {
    selectAll() {
      return this.item_name.length === this.item_names.length;
    },
    selectSome() {
      return this.item_name.length > 0 && !this.selectAll;
    },
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAllMark() {
      return this.mark.length === this.marks.length;
    },
    selectSomeMark() {
      return this.mark.length > 0 && !this.selectAllMark;
    },

    icon1() {
      if (this.selectAllMark) return "ni-fat-remove text-lg";
      if (this.selectSomeMark) return "ni-fat-delete";
      return "fa-square-o";
    },

    selectAllCompanyWareHouse() {
      return this.company_warehouse.length === this.company_warehouses.length;
    },
    selectSomeCompanyWareHouse() {
      return (
        this.company_warehouse.length > 0 && !this.selectAllCompanyWareHouse
      );
    },

    icon2() {
      if (this.selectAllCompanyWareHouse) return "ni-fat-remove text-lg";
      if (this.selectSomeCompanyWareHouse) return "ni-fat-delete";
      return "fa-square-o";
    },

    selectAllGrade() {
      return this.grade.length === this.grades.length;
    },
    selectSomeGrade() {
      return this.grade.length > 0 && !this.selectAllGrade;
    },

    icon3() {
      if (this.selectAllGrade) return "ni-fat-remove text-lg";
      if (this.selectSomeGrade) return "ni-fat-delete";
      return "fa-square-o";
    },
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
    },
    deep: true,
  },
};
</script>

<style></style>
